<template>
    <div>
        <form @submit.prevent="updateRecord">

            <!-- paste from hq below here -->

            <div class="">
                <h1>Offices</h1>
                <div>
                    <Checkbox
                        class="flex center bottom-15"
                        id="active"
                        value=1
                        v-model="record_data.active"
                        label="Active"
                        :disabled="!isEditable"
                    /></div>
                <div class="flex colx4 wrap">
                    <div><Input
                        type="text"
                        name="office_code"
                        id="office_code"
                        placeholder="Office Code"
                        v-model="record_data.office_code"
                        label="Office Code"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="office_name"
                        id="office_name"
                        placeholder="Office Name"
                        v-model="record_data.office_name"
                        label="Office Name"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="office_address"
                        id="office_address"
                        placeholder="Office Address"
                        v-model="record_data.office_address"
                        label="Office Address"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        v-model="record_data.city"
                        label="City"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="us_state"
                        id="us_state"
                        placeholder="Us State"
                        v-model="record_data.us_state"
                        label="Us State"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Zip"
                        v-model="record_data.zip"
                        label="Zip"
                        :disabled="!isEditable"
                    /></div>
                </div>
                <!--form_extra-->
            </div>




            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    const api_root = 'offices'

    export default {
        name: 'OfficeDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    office_code : '',
                    office_name : '',
                    office_address : '',
                    city : '',
                    us_state : '',
                    zip : '',
                },

                isEditable : 1,

                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }
                }
            },
            async updateRecord(e) {
                e.preventDefault()
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        const res = await this.$api.put(this.record_actions.updateRecord(this.record_id), this.record_data)
                        const record_id = parseInt(res.data.recId[0])
                        this.$toasted.success('Successfully updated Record.')
                        await this.$router.push({ name: this.name, params: { record_id: record_id } })
                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.')
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                  await this.init()
            },
        },
    }
</script>
